import React from 'react'
import Helmet from 'react-helmet'
import SEO from '../seo'
import Layout from '../Layout'
import Articles from './Articles'

import './Issue.css'
import CheckoutButton from '../CheckoutButton'

const Issue = ({ issue }) => (
  <Layout>
    <SEO
      title={`CONTANGO - ${issue.issue_title.text}`}
      keywords={['Contango Journal', 'Political Theory']}
    />
    <Helmet title={`${issue.issue_title.text}`} />
    <div className="issue">
      <div className="issueContent">
        <h1>{issue.issue_title.text}</h1>
        <div
          className="blog-issue-content"
          dangerouslySetInnerHTML={{ __html: issue.issue_description.html }}
        />
        <Articles articles={issue.issue_articles} />
        <div
          dangerouslySetInnerHTML={{
            __html: issue.issue_artists_description.html,
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: issue.issue_media_information.html,
          }}
        />
        <CheckoutButton />
      </div>
      <img
        alt={`Cover for Issue "${issue.issue_title.text}"`}
        className="issueCoverImage"
        src={issue.issue_cover_image.url}
      />
    </div>
  </Layout>
)

export default Issue
