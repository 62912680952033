import React from 'react'

import './CheckoutButton.css'

const CheckoutButton = () => (
  <div>
    <form
      action="https://www.paypal.com/cgi-bin/webscr"
      className="checkoutButton"
      method="post"
      target="_top"
    >
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value="D7FFJBGSNB2RN" />
      <input
        type="image"
        className="purchaseImage"
        src="https://prismic-io.s3.amazonaws.com/contango-journal%2F9eecc84a-f491-4d32-b9fe-9ac8a3b474c9_purchase_img.png"
        border="0"
        name="submit"
        alt="PayPal - The safer, easier way to pay online!"
      />
      <img
        alt=""
        border="0"
        src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
        width="1"
        height="1"
      />
    </form>
  </div>
)

export default CheckoutButton
