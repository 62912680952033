import React from 'react'
import { Link } from 'gatsby'

const Articles = ({ articles = [] }) => {
  if (articles) {
    articles = articles.map(({ article }) => {
      return { data: article.document[0].data, uid: article.uid }
    })
  }
  return articles.map((article, index) => (
    <div key={index}>
      {index === 0 ? <h1>Writers</h1> : <></>}
      <div>
        <h2>
          <Link to={`/article/${article.uid}`}>
            {article.data.article_title.text}
          </Link>
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: article.data.article_description.html,
          }}
        />
      </div>
    </div>
  ))
}

export default Articles
